var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-uploader",
    { attrs: { afterRead: _vm.afterRead, beforeRead: _vm.beforeRead } },
    [
      _c(
        "div",
        { staticClass: "user_avatar_upload" },
        [
          _vm.url
            ? _c("img", {
                class: _vm.$attrs.custClass,
                attrs: { src: _vm.url, width: "40", height: "40" },
              })
            : _c("van-icon", { attrs: { name: "camera_full" } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }