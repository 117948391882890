var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    { attrs: { route: "", "safe-area-inset-bottom": true } },
    [
      _c(
        "van-tabbar-item",
        { attrs: { icon: "home-o", replace: "", to: "/" } },
        [_vm._v("首页")]
      ),
      _vm._v(" "),
      _c(
        "van-tabbar-item",
        { attrs: { icon: "user-o", replace: "", to: "/my" } },
        [_vm._v("我的")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }