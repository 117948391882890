"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.regexp.split");

var _toConsumableArray2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/toConsumableArray.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'VanFieldSelectDate',
  model: {
    prop: 'value',
    type: 'change'
  },
  props: {
    // 默认v-model 修改的值是value
    value: {// type: String
    },
    inputAlign: {
      type: String,
      default: function _default() {
        return 'left';
      }
    },
    field: {
      type: String
    },
    minDate: {
      default: function _default() {
        return new Date(1900, 1, 1);
      }
    },
    maxDate: {
      default: function _default() {
        return new Date(2025, 10, 1);
      }
    },
    currentDate: {
      default: function _default() {
        return new Date();
      }
    }
  },
  watch: {
    value: {
      handler: function handler(newVal) {
        console.log('watch value........');
        console.log(newVal);
        this.result = newVal;
      },
      deep: true
    },
    show: function show(newVal) {
      if (this.value) {
        var _ref = (0, _toConsumableArray2.default)(this.value.split('-')),
            year = _ref[0],
            month = _ref[1],
            day = _ref[2];

        this.comCurrentDate = new Date(year, month - 1, day);
      }
    },
    result: function result(newVal) {
      console.log('result value........');
      this.$emit('input', newVal);
    }
  },
  data: function data() {
    return {
      show: false,
      result: this.value,
      comMaxDate: this.maxDate,
      comMinDate: this.minDate,
      comCurrentDate: this.currentDate,
      style: ''
    };
  },
  methods: {
    onConfirm: function onConfirm(value) {
      var date = value;
      var m = date.getMonth() + 1;
      var d = date.getDate();

      if (m >= 1 && m <= 9) {
        m = '0' + m;
      }

      if (d >= 0 && d <= 9) {
        d = '0' + d;
      }

      this.result = date.getFullYear() + '-' + m + '-' + d;
      this.show = !this.show;
      this.$emit('confirm', (0, _defineProperty2.default)({}, this.field, this.result));
    },
    onCancel: function onCancel(obj) {
      this.show = !this.show;
    }
  }
};
exports.default = _default2;