"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  model: {
    prop: 'value',
    type: 'change'
  },
  watch: {
    value: {
      handler: function handler(newval, oldval) {
        var _this = this;

        this.compoColumns.values = this.columns.map(function (res, index) {
          if (res.id === parseInt(_this.selectedId)) {
            _this.compoColumns.defaultIndex = index;
            _this.result = res.value;
          }

          return res.value;
        });
      },
      deep: true,
      immediate: true
    },
    showPicker: function showPicker(val) {
      /* 使用了v-model的组件会自动监听 input 事件,
       * 并把这个input事件所携带的值 传递给v-model所绑定的属性,
       * 这样组件内部的值就给到了父组件了
       */
      this.$emit('input', val); // 传值给父组件, 让父组件监听到这个变化
    }
  },
  props: {
    // 默认v-model 修改的值是value
    value: {},
    columns: {
      type: Array
    },
    selectedId: {},
    field: {
      type: String
    }
  },
  data: function data() {
    return {
      result: '',
      show: false,
      compoColumns: {
        values: [],
        defaultIndex: 0
      }
    };
  },
  methods: {
    confirm: function confirm(value, index) {
      this.show = !this.show;
      this.$emit('confirm', (0, _defineProperty2.default)({}, this.field, this.columns[index].id));
    }
  },
  created: function created() {}
};
exports.default = _default;