"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/tabbar-item/style");

var _tabbarItem = _interopRequireDefault(require("vant/es/tabbar-item"));

require("core-js/modules/es6.function.name");

require("vant/es/tabbar/style");

var _tabbar = _interopRequireDefault(require("vant/es/tabbar"));

var _components;

var _default = {
  name: 'FooterTabbar',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _tabbar.default.name, _tabbar.default), (0, _defineProperty2.default)(_components, _tabbarItem.default.name, _tabbarItem.default), _components)
};
exports.default = _default;