"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _index = require("@/api/user/index");

var _store = _interopRequireDefault(require("@/store"));

//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'VerifyCodeBtn',
  watch: {
    type: {
      handler: function handler(newVal) {
        console.log('watch type handler'); // 安全验证使用当前手机号

        if (['modphone', 'modpwd'].includes(this.type)) {
          this.sendCodePhone = _store.default.getters.user.phone;
        }
      },
      immediate: true
    },
    value: {
      handler: function handler(newVal) {
        if (!['modphone', 'modpwd'].includes(this.type)) {
          this.sendCodePhone = newVal;
        }
      }
    }
  },
  computed: {
    disabled: function disabled() {
      if (this.sendCodePhone && this.sendCodePhone.length === 11 && this.codeRestTime <= 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  components: {},
  props: {
    value: {
      default: function _default() {}
    },
    btnMsg: {
      type: String,
      default: '获取验证码'
    },
    restTime: {
      type: Number,
      default: 30
    }
  },
  data: function data() {
    return {
      // 发送验证码的手机号
      sendCodePhone: this.value,
      // 用户当前的手机号
      userPhone: _store.default.getters.user ? _store.default.getters.user.phone : '',
      codeRestTime: 0,
      type: this.$attrs.type
    };
  },
  methods: {
    btnClick: function btnClick() {
      var _this = this;

      this.getSms().then(function (res) {
        if (res) {
          _this.codeRestTime = _this.restTime;
          var timer = setInterval(function () {
            --_this.codeRestTime;

            if (!_this.codeRestTime) {
              clearInterval(timer);
              timer = null;
            }
          }, 1000);

          _this.$emit('sendVerifyCode');
        }
      });
    },
    // 获取验证码
    getSms: function getSms() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        var type = _this2.$attrs.type; // 默认当前model账号

        var phone = _this2.sendCodePhone;

        if (['login', 'bind'].includes(type)) {
          // 登录或者绑定获取验证码
          (0, _index.captcha)({
            phone: phone,
            type: type
          }).then(function (res) {
            if (!res.code) {
              _this2.$toast('发送成功');

              resolve(true);
            }
          });
        } else {
          // 更改手机号密码安全验证     更改手机号验证码
          if (type === 'newPhone' && _this2.sendCodePhone === _this2.userPhone) {
            _this2.$toast('换绑手机号不可以和原手机号相同');

            resolve(false);
            return false;
          }

          (0, _index.userIdentityVerifyCaptcha)({
            phone: phone,
            type: type
          }).then(function (res) {
            if (!res.code) {
              _this2.$toast('发送成功');

              resolve(true);
            }
          });
        }
      });
    }
  }
};
exports.default = _default2;